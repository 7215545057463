var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageTitle',{attrs:{"items":{
      title: '求人広告',
      subTitle: '特徴管理',
      buttons: [
        {
          title: '詳細条件検索',
          action: function () {
            _vm.toggleSearch = !_vm.toggleSearch
          },
          class: ['bg-white'],
          others: {
            outlined: true
          }
        },
        {
          title: '新規作成',
          icon: 'mdi-plus-circle',
          action: function () {
            _vm.launchNew = true
          }
        }
      ]
    }}}),_c('v-fade-transition',[(_vm.toggleSearch)?_c('SearchBox',_vm._b({staticClass:"mb-5",attrs:{"searchPlacholder":"プルダウン表示項目"},on:{"toggleSearch":function($event){_vm.toggleSearch = false},"search-table":_vm.searchTable}},'SearchBox',{ toggleSearch: _vm.toggleSearch },false)):_vm._e()],1),_c('DataTable',{staticClass:"school-table",attrs:{"headers":_vm.headers,"items":_vm.getAllInternshipFeatures,"total-records":_vm.totalRecords ? _vm.totalRecords : 0,"number-of-pages":_vm.totalPages ? _vm.totalPages : 0},on:{"update:options":_vm.updateTable,"click:row":function($event){_vm.launchEdit = true
      _vm.editItem = $event}},scopedSlots:_vm._u([{key:"item.delete",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","color":"transparent"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteInitiate(item.id)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$delete")])],1)]}}])}),_c('InternshipFeatureDialog',{attrs:{"launch":_vm.launchNew,"edit":false},on:{"update:launch":function($event){_vm.launchNew=$event},"refresh":_vm.getDataFromApi}}),_c('InternshipFeatureDialog',{attrs:{"launch":_vm.launchEdit,"item":_vm.editItem},on:{"update:launch":function($event){_vm.launchEdit=$event},"update":_vm.update,"refresh":_vm.getDataFromApi}}),_c('SimpleModel',{attrs:{"text":"この特徴を削除してよろしいですか？","dialog":_vm.dialog.delete,"submitButtonText":'削除する'},on:{"submitSuccess":function($event){return _vm.deleteInternshipFeature()},"closeModel":function($event){_vm.dialog.delete = false}}}),_c('SuccessModel',{attrs:{"text":_vm.alertText,"buttonText":"とじる","routeName":_vm.routeName,"dialog":_vm.successDialog},on:{"closeModel":function($event){_vm.successDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }